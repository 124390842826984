.custom-sidebar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  /* overflow: hidden; */
  background-color: #ffffff;
  z-index: 100;
  row-gap: 30px;
  /* box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22); */
}
.link-item {
  height: 50px;
  width: 100%;
}
.link-item:hover {
  background-color: #F3F5F8;
}

.separator {
  width: 100%;
  border-bottom: 1px solid #F3F5F8;
}

.selected-link {
  color: #000089;
  font-weight: 500;
}
.unselected-link {
  color: #a5adb8;
}
.close-sidebar-icon {
  border-radius: 14px;
  padding-right: 3px;
  color: #94A3B8 !important;
  background-color: #F3F5F8;
  width: 28px;
  height: 28px;
  flex-shrink: 0;    
  position: absolute;
  right: -14px;
}

.close-sidebar-icon:hover {
  background-color: #e5e4e2;
  transition: background-color 0.4s;
}

.selected-icon {
  color: #000089;
}

.sidebar-header {
  width: 100%;
}