.header {
  background-color: #343c3d;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}

.bg-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.footer-bg {
  background-color: #ffffff;
}

.footer-separator-mobile {
  margin-bottom: 8px;
  margin-top: 8px;
  border-top: 1px solid #5f5f5f;
}

.vertical-line {
  height: 100%;
  width: 1px;
  background-color: #5f5f5f;
}

/* Section 1 */
.intro-bg {
  background-image: url("../../assets/intro-bg1v2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* background-color: #999; */
}

.navbar.is-transparent {
  background-color: transparent;
  position: absolute;
  width: 100%;
}

.logos {
  margin: 0 auto;
}

.kayrros_logo {
  width: 175px;
  height: 48px;
}

/* Section 2 */

.section2 {
  /* -webkit-box-shadow: 0 50px 100px 165px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0 50px 100px 165px rgba(255, 255, 255, 1);
  box-shadow: 0 50px 100px 165px rgba(255, 255, 255, 1); */
}

.section2 .columns .count-widget {
  /* border-top: solid 1px rgba(165, 173, 184, 1);
  border-bottom: solid 1px rgba(165, 173, 184, 1); */
}

.section2 .columns .count-widget:nth-child(2) {
  /* border-left: solid 1px rgba(165, 173, 184, 1);
  border-right: solid 1px rgba(165, 173, 184, 1); */
}

.section2 .columns .column .count {
  font-weight: 200;
  font-size: 90px !important;
  line-height: 106px;
  /* identical to box height */

  text-align: center;

  background: linear-gradient(47.13deg, #ff5353 20.62%, #ffb891 80.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

/* section 3 */
.section3 {
  background-color: #26292C;
}

.section3 figure {
  height: 97%;
}


.image-holder {
  /* border-left: solid 1px #A5ADB8 */
}

.section3 .external-link {
  border: solid 1px #f1f2f4;
}

.section3 .external-link:hover {
  text-decoration: none;
}

/* Section 5 */

.divider {
  border-top: solid 1px #a5adb8;
}

.section5 .image img {
  width: 100%;
}

/* Section 6 : search */

.section7 {
  background-image: linear-gradient(to right, #71b0f3, #182db9);
  /* background-color: #000089; */
}

.section7 .input-container,
.section7 .input-container input {
  background-color: #F1F2F4;
}

.section7 .suggestions-container {
  background-color: #f1f2f4;
}

/* Section: Approach */

.approach {
  background-color: #26292C !important;

}

.searchSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.section3 .columns .column div div .metricRow .metricContainer .count {
  font-weight: 200;
  font-size: 32px !important;
  line-height: 106px;
  /* identical to box height */

  /* text-align: center; */

  background: linear-gradient(47.13deg, #ff5353 0%, #ffb891 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.section3 .columns .column div div .metricRow .metricContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: fit-content;
}

.section3 .columns .column div div .metricRow {
  display: flex;
  justify-content: space-around;
}

/* Global css  */

a.external-link:hover {
  background-color: #ffffff;
  color: #2F3337 !important;
}

.welcome-header1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  /* 41.6px */
  letter-spacing: 9.28px;
}

.welcome-header2 {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 67.2px */
}

.records-description {
  line-height: 150% !important;
}

/* .desc {
  white-space: pre-line;
} */
.title-tag {
  color: #000089;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
  /* 30px */
}

.title-tag-container {
  height: fit-content;
  width: fit-content !important;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 57px;
  background: #EBF1FF;
}

.model-description {
  color: #A5ADB8;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}


@media all and (max-width: 800px) {
  .image-wrapper {
    border-left: none;
  }

  .section3 .image img {
    width: 100%;
    height: 100%;
  }

  .section5 .image img {
    width: 100%;
    height: 100%;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:769px) and (max-width:1024px) {
  .asset {
    object-fit: scale-down;
  }
}

@media (min-width:961px) {}

/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {

  /* .section5.feature2 .image img{
    width: 100%;
    height: 400px;
  } */
  .asset {
    object-fit: scale-down;
  }

}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

  /* .section5.feature2 .image img{
    width: 100%;
    height: 400px;
  } */
  .asset {
    object-fit: scale-down;
  }
}

@media only screen and (min-width : 1025px) {
  .asset {
    object-fit: scale-down;
  }
}


@media only screen and (max-width : 1024px) {
  .asset {
    object-fit: scale-down !important;
  }
}


.asset {
  height: 100%;
  object-fit: cover;
}

p {
  line-height: 120% !important;
}

strong {
  color: #A5ADB8 !important;
  font-weight: 700 !important;
}

html {
  scroll-behavior: smooth;
}

.video-container .column:nth-child(1) {
  background-color: #000;
}


#search-section .suggestions-container {
  position: relative;
}

.model-toggle-container {
  width: 600px;
  margin: 40px auto;
  text-align: center;
}

.description-container {
  width: 100%;
}

.desc-text {
  line-height: 150% !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.header-color {
  color: #2F3337;
}

.font-16 {
  font-size: 16px !important;
}

.font-20 {
  font-size: 20px !important;
}

.desc-light {
  color: #94A3B8 !important;
}

.outlook-title1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.outlook-title2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  color: #FFF;
}

.outlook-row-title {
  font-size: 14px !important;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
color: #FFF;
}
.outlook-metric-desc {
  font-size: 16px !important;
font-style: normal;
font-weight: 400;
line-height: 150% !important; /* 21px */
color: #A5ADB8;
}

.model-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 3px;
}

.button-inactive {
  color: #94A3B8;
  background-color: #fff;
}

.button-active {
  color: #FFF;
}

.model-options-title {
  font-size: 48px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: 120% !important; /* 57.6px */
 color: #2F3337;
}

.image-desc-container div {
  width: 48%;
}

.model-title {
  font-size: 48px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: 120% !important; /* 57.6px */
color: #2F3337;
}

.approach-container {
  margin: auto;
  text-align: center;
}

.approach-title {
  color: var(--White, var(--surface-bg, #FFF));
text-align: center;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 57.6px */
}