.marign-class {
  /* margin-bottom: 32px; */
}

@media screen and (min-width: 1920px) {
  .marign-class {
    /* margin-bottom: 62px; */
  }
}


.icons {
  width: 100%;
}

.meter-container {
  margin: 20px auto;
  position: relative;
  width: 250px;
  height: 130px;
}

.circle1 {
  width: 250px;
  height: 130px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.circle1-outer {
  position: absolute;
  width: 250px;
  height: 130px;
  border-top-left-radius: 125px;
  border-top-right-radius: 125px;
  background-color: #F3F5F8;
}

.circle1-inner {
  position: absolute;
  width: 210px;
  height: 110px;
  left: 20px;
  bottom: 0px;
  border-top-left-radius: 125px;
  border-top-right-radius: 125px;
  background-color: #fff;
}

.circle2 {
  width: 170px;
  height: 90px;
  position: absolute;
  left: 40px;
  bottom: 0px;
}

.circle2-outer {
  position: absolute;
  background-color: #F3F5F8;
  width: 170px;
  height: 90px;
  border-top-left-radius: 85px;
  border-top-right-radius: 85px;
}

.circle2-inner {
  position: absolute;
  background-color: #fff;
  width: 130px;
  height: 70px;
  left: 20px;
  bottom: 0;
  border-top-left-radius: 125px;
  border-top-right-radius: 125px;
}

.circle3 {
  width: 90px;
  height: 50px;
  position: absolute;
  left: 80px;
  bottom: 0px;
}

.circle3-outer {
  position: absolute;
  background-color: #F3F5F8;
  width: 90px;
  height: 50px;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
}

.circle3-inner {
  position: absolute;
  background-color: #fff;
  width: 50px;
  height: 30px;
  left: 20px;
  bottom: 0;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}

.icons {
  width: 100%;
}

.chart-container {
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin: 0 auto;
}

.chart-container-live {
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin: 0 auto;
}

.uppercase-text {
  text-transform: uppercase;
}

.card-desc {
  color: #94A3B8;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140% !important;
  /* 21px */
}

.show-more-title {
  color: #D1DDEB;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.show-more-desc {
  color: #7B848E;

  text-align: justify;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140% !important;
  /* 21px */
}

.value-text {
  color: #2F3337;

  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.667px !important;
  /* 46.667% */

  white-space: nowrap;
  text-overflow: ellipsis;
}

.value-subtext {
  color: #94A3B8;
  text-align: center;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 9.333px !important;
}

.value-date {
  color: #BDCBDE;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}