.mapboxgl-ctrl-scale {
    border: none;
    height: fit-content;
    border-radius: 3px;

    color: #94A3B8;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.196px;
    background: #F3F5F8;

    display: inline-flex;
    padding: 5.887px 3.925px;
    justify-content: center;
    align-items: center;
    gap: 3.925px;

}

.scale-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: fit-content;
}

.zoom-container {
    right: 20px;
    top: 70px;
    width: 36px;
}

.mapboxgl-ctrl-group {
    border-radius: 3.5px;

}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22Group%22%3E%0A%3Cpath%20id%3D%22Vector%22%20d%3D%22M12.833%2012.8335V5.8335H15.1663V12.8335H22.1663V15.1668H15.1663V22.1668H12.833V15.1668H5.83301V12.8335H12.833Z%22%20fill%3D%22%2394A3B8%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A) !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22Group%22%3E%0A%3Cpath%20id%3D%22Vector%22%20d%3D%22M5.83301%2012.8335H22.1663V15.1668H5.83301V12.8335Z%22%20fill%3D%22%2394A3B8%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A) !important;
}

.mapboxgl-ctrl-icon {
    margin: auto;
}

.deck-compoenent {
    position: relative;
}

.tooltip-container {
    position: absolute;
    border-radius: 4px;
    background: var(--surface-bg, #FFF);
    display: flex;
    width: 211px;
    height: fit-content;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-8px, 8px);

    flex-shrink: 0;
}

.tooltip-text {
    color: var(--Texte-principal, #2F3337);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}

.tooltip-title {
    width: 100%;
}

.tooltip-field {
    color: #94A3B8;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
}