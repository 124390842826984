@charset "utf-8";
// @import url("https://fonts.googleapis.com/css2?family=Public+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@200;400;700;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/remixicon@3.2.0/fonts/remixicon.css");
@import "../node_modules/mapbox-gl/dist/mapbox-gl.css";

@import "./styles/derived-variables.scss";
@import "./styles/media-quiries.css";

$input-focus-border-color: hsl(0, 0%, 86%);
$input-focus-box-shadow-color: #f5f5f5;
$input-radius: 30px;
$family-sans-serif: "Public Sans", sans-serif;
$family-primary: $family-sans-serif;

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    width: 100vw;
    white-space: pre-line;
}
.kayrros-logo-header-container {
    height: 33px;
    width: 120px;
}
.axa-logo-header-container {
    height: 33px;
    width: 33px;
}

@import "../node_modules//bulma/bulma.sass";
