.footer-divider{
    border-top: solid 1px #D9DBE9;
}

.address {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    color: #2F3337;
}

.footer-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2F3337;
}

.footer-text-decoration-underline {
    text-decoration-line: underline;

}

.footer-item {
    color: #2F3337;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 24px */
}

.footer-title {
    color: #2F3337;

font-size: 20px !important;
font-style: normal;
font-weight: 600;
line-height: 150% !important; /* 30px */
}