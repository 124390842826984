
// Colors
$grey:  #A5ADB8;

// Typography
$family-sans-serif: 'Public Sans', sans-serif;

// Font sizes
$size-1: 70px;

$size-2: 56px;

$size-3: 52px;

$size-4: 36px;

$size-5: 24px;

$size-6: 20px;

$size-7: 18px;

$size-8: 16px;

$size-9: 12px;

$weight-light: 200 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// Spacing ( padding / margin )
$spacing-values: ("0": 0, "1": 2px, "2": 4px, "3": 8px, "4": 16px, "5": 32px, "6": 64px, "auto": auto) !default


