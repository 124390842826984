.hamburger-icon {
  border-radius: 30px;
  padding-right: 8px;
  padding-left: 8px;
}

.hamburger-icon:hover {
  background-color: #d8d8d8;
  transition: background-color 0.4s;
}

.hamburger-icon-container {
  width: 70px;
  height: 100vh;
  padding-top: 30px;
  padding-left: 30px;
}

.sidebar-dashboard {
  height: 100vh;
  overflow: hidden;
  background-color: #ffffff;
  row-gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.home-icon {
  margin-top: 10px;
}

.empty-midspace {
  flex: 1;
  width: 100%;
  border-radius: 12px;
  padding: 18px;
}

.icons-container {
  height: 100%;
}

.selected-icon {
  color: #000089;
}

.sidebar-logo {
  width: 32px;
  height: 32px;
}
.close-sidebar-icon {
  border-radius: 14px;
  padding-right: 3px;
  color: #94A3B8 !important;
  background-color: #F3F5F8;
  width: 28px;
  height: 28px;
  flex-shrink: 0;    
  position: absolute;
  right: -14px;
}

.close-sidebar-icon:hover {
  background-color: #e5e4e2;
  transition: background-color 0.4s;
}