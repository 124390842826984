.input-container {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #F3F5F8;
  border-radius: 3px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: box-shadow 0.3s;
  color: #5e656e;
  border-left: 1px solid #f1f2f4;
  border-right: 1px solid #f1f2f4;
}
.input-element {
  padding-left: 8px;
  background-color: #F3F5F8;
  font-size: 16px;
  font-weight: 400;
  border: none;
  outline: none;
  flex-grow: 1;
}
/* .input-container:hover {
  box-shadow: 0px 1px 3px rgb(192, 185, 185) !important;
} */
.suggestions-container {
  position: absolute;
  z-index: 50;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #ffffff;
  overflow: hidden;
  border-left: 1px solid #f1f2f4;
  border-right: 1px solid #f1f2f4;
  border-bottom: 1px solid #f1f2f4;
}
.suggestion-separator {
  margin: 0px;
  margin-left: 2%;
  margin-right: 2%;
  border-top: 2px solid #e8eaed;
}

.search-btn:hover {
  opacity: 0.7;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #e6f1fa;
  color: #373d47;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 15px;
  border: solid 1px #3c97d6;
  text-align: left;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 13;
  top: 100%;
  left: 185%;
  margin-left: -60px;
  margin-top: 10px;
  font-size: 14px;
}

.arrow {
  position: absolute;
  overflow: hidden;
  visibility: hidden;
}
.arrow:after {
  content: "";
  background: #e6f1fa;
  width: 18.387553041px;
  height: 18.387553041px;
  position: absolute;
  border: 1px solid #3c97d6;
  transform-origin: 0 0;
}

.arrow-top {
  width: 26px;
  height: 13px;
  left: 50%;
  bottom: -11px;
  margin-left: -13px;
  z-index: 14;
}
.arrow-top:after {
  left: 13px;
  top: 10;
  transform: rotate(45deg);
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext,
.tooltip:hover .arrow {
  visibility: visible;
}

.searchbar-container {
  margin: 0px auto;
}