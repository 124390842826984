.slider {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

.overlay-text-title {
  color: #FFF !important;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 140% !important; /* 29.4px */
  text-transform: uppercase;
}

.overlay-text-desc {
  color: #ECECEC !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140% !important; /* 21px */
  flex: 1;
}