.custom-sidebar-gradient-card-button {
  border: none;
  outline: none;
  line-height: normal;
  color: #ffffff;
  background-color: #000089;
  border-radius: 45px;
  transition: background-color 0.3s ease-in-out;
  padding: 10px 15px;
}

.custom-sidebar-gradient-card-button:hover {
  cursor: pointer;
  color: #ffffff;
  line-height: normal;
  background-color: #3535c0;
}
