

.info-icon {
    right: 20px;
    top: 20px;
    width: 36px;
    height: 36px;
    position: absolute;
    background: #F3F5F8;
    border-radius: 3.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50px;
    right: -65px;
    position: absolute;
    z-index: 1;
}

.info-body {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 50px 0px rgba(215, 220, 228, 0.50);
    width: 200px;
    padding: 10px 20px;
}

.info-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #2F3337;
    text-align: left;
}

.info-color {
    width: 30px;
height: 30px;
}

.info-text {
    color: #94A3B8;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 18.2px */
}

.mapstyles-container {
    position: absolute;
    bottom: 40px;
    left: 20px;
}

.mapstyles-item {
    height: 60px;
    width: 60px;
    margin: 5px;
    cursor: pointer;
}