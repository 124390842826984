.custom-header {
  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* min-height: 6rem; */
  width: 100%;
  background-color: #fff;
  height: 72px;
  z-index: 1;
}
.hamburger-icon,
.hamburger-icon-home {
  border-radius: 30px;
  padding-right: 8px;
  padding-left: 8px;
  color: #000;
}

.hamburger-icon:hover {
  background-color: #d8d8d8;
  transition: background-color 0.4s;
}
.hamburger-icon-home:hover {
  background-color: #606060;
  transition: background-color 0.4s;
}

.search-button {
  background-color: #000089;
  transition: background-color 0.4s;
}

.search-button:hover {
  background-color: #fff;
  color: #000 !important;
}

.logos {
  /* position: absolute;
  left: 50%; */
  /* margin-left: -150px; */
  width: 300px;
}

.drawer-container {
  position: fixed;
  z-index: 2;
}

.header-container {
  position: relative;;
}