.bg-search-details-container {
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
}
.dashboard-content {
  background-color: #F9FAFB;
  overflow-y: scroll;
  z-index: 1;
  /* overflow: hidden; */
  height: 100%;
}
.risk-box {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  top: 0;
  left: 0;
}
.risk-box-learnmore {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: #2f3337;
  padding: 20px;
  border-radius: 12px;
  top: 0;
  left: 0;
  /* overflow: auto; */
}
.live-icon-container {
  /* background-color: #e0e0e0; */
  color: #000089;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.live-icon-container-inactive, .live-icon-container-disabled {
  color: #94A3B8;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.live-icon-container-inactive:hover {
  color: #94A3B8;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}
.live-tooltip {
  position: absolute;
  z-index: 20;
  top: 56px;
  right: 0px;
  width: 300%;
  padding: 14px;
  background-color: #e6f1fa;
  border: 1px solid #3c97d6;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
}
.tooltip-close-text:hover {
  cursor: pointer;
  color: #26292c;
  transition: color 0.3s ease-in-out;
}
.map-container {
  position: relative !important;
}
.map-component {
  /* overflow: hidden !important; */
  position: relative !important;
  width: 100% !important;
  height: 680px !important;
  border-radius: 11px !important;
  /* box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15) !important; */
  overflow: hidden;
}
.risk-box:hover,
.risk-box-learnmore:hover {
  transition: box-shadow 0.3s;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.slider-container {
  position: absolute;
  top: 143px;
  right: 70px;
  z-index: 12;
  width: 30%;
}
.slider-element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}
.slider-element::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  right: 11px;
  top: 25%;
  border: 15px solid transparent;
  border-right: 0;
  border-left: 15px solid #ffffff;
  transform: translate(calc(100% + 5px), -50%);
  z-index: 12;
}
.slider-switch-container {
  position: absolute;
  top: 143px;
  right: 20px;
  z-index: 1;
  display: inline;
}
.slider-switch {
  width: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 3.5px;
  position: relative;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease-in-out;
}
.slider-switch:hover {
  background-color: #e5e4e2;
}
.custom-learnmore-button {
  font-size: 14px;
  border: solid 1px #2f3337;
  outline: none;
  background-color: transparent;
  border-radius: 60px;
  padding: 6px 15px;
  transition: all 0.3s ease-in-out;
}
.custom-learnmore-button:hover {
  cursor: pointer;
  outline: none;
  background-color: #2f3337;
  color: #ffffff;
}
.banner-learnmore-button {
  font-size: 14px;
  line-height: normal;
  border: solid 1px #ffffff;
  color: #ffffff;
  outline: none;
  background-color: transparent;
  border-radius: 60px;
  padding: 6px 15px;
  transition: all 0.3s ease-in-out;
}
.banner-learnmore-button:hover {
  cursor: pointer;
  outline: none;
  background-color: #2f3337;
  border: solid 1px #2f3337;
  color: #ffffff;
}
.sidebar-card-container {
  height: 189px;
  width: 100%;
  border-radius: 12px;
  padding: 18px;
}
.header-buttons {
  width: 50%;
}
.dashboard-header {
  height: 90px;
  background-color: #fff;
}
.dashboard-header-item {
  width: 49%;
  position: relative;
}
.search-container {
  width: 50%;
}

.card-container {
  width: 45%;
  height: 330px;
  position: relative;
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.live-button-container {
  width: fit-content;
  white-space: nowrap;
}

.tour-container {
  width: 287px;
  height: fit-content;
  background-color: #2F3337;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
}

.tour-title {
  color: #F5F5F5;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal !important;
text-align: left;
}

.tour-subtitle {
  color: #fff;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 140% !important; /* 18.2px */
}

.tour-arrow {
  cursor: pointer;
  margin: 0 10px;
}

.greyed {
  color: #D2DBE8;
}

.body-item {
  width: 49% !important;
}

.dashboard-body {
  flex: 1;
}

.search-details-title {
  color: #2F3337;
  font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 96.97% */
}

.search-details-subtitle {
  color: #94A3B8;
font-size: 33px;
font-style: normal;
font-weight: 400;
line-height: 32px;
}
.search-details-title1 {
  color: #94A3B8;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 105.263% */
}
.first-step {
  position: absolute;
  left: 220px;
  bottom: 30px;
}
.second-step {
  position: absolute;
  left: 50%;
  bottom: 30px;
}
.third-step {
  position: absolute;
  left: 50%;
  top: 50%;
}
.fourth-step {
  position: absolute;
  left: 25%;
  top: 45%;

}
.fifth-step {
  position: absolute;
  left: 50%;
  bottom: 20px;
}
.sixth-step {
  position: absolute;
  left: 50%;
  bottom: 20px;
}
.seventh-step {
  position: absolute;
  left: 50%;
  bottom: 20px;
}

.step-container {
  position: relative;
}

.close-icon {
  position: absolute;
  right: 10px;
}

.alert-button {
  border: none;
  position: relative;
}

.alert-email-incorrect-text {
  color: #AF4338;
text-align: justify;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 14.3px */
}

.alert-email-exits-text {
  color: #F8AC54;
text-align: justify;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 14.3px */
}

.alert-completedTitle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-completedTitle {
  color: #2F3337;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
/* text-align: center; */
}

.alert-completedSubTitle {
  color: #94A3B8;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
}

.alert-container-complete {
  position: absolute;
top: 20px;
z-index: 12;
left: -150%;
width: 498px;
height: 214px;

}

.alert-container {
  position: absolute;
top: 20px;
z-index: 12;
left: -150%;
width: 498px;
height: 436px;
}

.alert-body {
  box-shadow: 0px 0px 50px 0px rgba(215, 220, 228, 0.50);

border-radius: 4px;
background: var(--surface-bg, #FFF);
  text-wrap: wrap;
  position: absolute;
  top: 20px;
}

.alert-arrow {
  position: absolute;
  width: 20px;
  /* height: 20px; */
  left: 50%;
  top: 0;
}

.alert-title {
  color: #2F3337;
text-align: center;
font-size: 27.895px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.alert-subtitle {
  color: #94A3B8;
  text-align: center;
  font-size: 15.94px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 23.91px */
}
.alert-subtitle-bold {
  font-weight: 700;
}

.alert-form {
  text-align: left;
  width: 418.425px;
  margin: auto;
}

.alert-email {
  border-radius: 4px;
    border: 0.996px solid #94A3B8;
    height: 49.813px;
}

.alert-email-error {
  border: 0.996px solid #AF4338;
}

.alert-email-exist-error {
  border: 0.996px solid #F8AC54;
}

.alert-input {
  border: none;
color: #2F3337;
outline: none;
flex: 1;

color: #2F3337;

font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.alert-agree-container {
  color: #94A3B8;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 130%;
}

.alert-submit-button {
  border-radius: 4px;
background: #000089;
height: 59.775px;
text-align: center;

color: var(--surface-bg, #FFF);

font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;

}

.alert-active {
  color: #000089;
}
.alert-inactive {
  color: unset;
}

.capture-container {
  position: absolute;
  /* height: 100%; */
  width: 100%;
  /* z-index: -9; */
  bottom: 10px;
}

.map-for-screeenshot {
  position: absolute;
  /* height: 100%; */
  width: 100%;
  z-index: -9;
  bottom: 10px;

}

.alert-disabled-title {
  color: #2F3337;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  width: 100%;
}

.alert-disabled-subtitle {
  color: #94A3B8;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}

.alert-disabled-submit-button {
  width: 255px;
  height: 23px;
  padding: 7.059px 14.118px;
  gap: 4.706px;
  border-radius: 3px;
  background: #EBF1FF;

  color: #000089;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 15px */
}

.map-image {
  height: 100%;
}

.alert-container-inactive {
  width: 287px;
height: 144px;
flex-shrink: 0;
position: absolute;
top: 20px;
z-index: 10;
left: -100%;
}

.popup-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
}


.screen-shot-container {
  width: 1600px;
  height: 760px;
}

.screen-shot-body {
  width: 100%;
  position: relative;
}

.screen-shot-mapcontainer {
  width: 50%;
}
.screen-shot-cards-container {
  width: 50%;
}

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel-image {
  position: absolute;
}